import AuthLogic from 'mg-js-core/lib/logics/AuthLogic'

export default class RouterLogic {
    static redirectMember(to, from, next) {
        if (AuthLogic.hasAccessToken()
            && AuthLogic.hasRole('member')) {

            if (to.name === 'member-layout') {
                next({name: 'member-dashboard'})
            } else {
                next()
            }

        } else if (AuthLogic.hasAccessToken()
            && AuthLogic.hasRole(['admin', 'teacher'])) {
            console.warn('redirect to admin')
            AuthLogic.logout().then(() => window.location.replace(`${process.env.VUE_APP_ADMIN_HOST}`))
        } else {
            AuthLogic
                .logout()
                .then(() => {
                    let name = 'register';
                    let query = {...to.query, redirect: to.path};

                    if (to.name === 'support-article') {
                        name = 'register-ressource';
                    }

                    if (to.name === 'member-cart') {
                        name = 'offline-cart';
                        query = null;

                        if(to.query) {
                            query = to.query;
                        }
                    }

                    next({
                        name,
                        params: to.params,
                        query,
                    });
                });
        }
    }
}

